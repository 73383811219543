import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import DavidWhoTextLogo from 'images/dw-text-logo.svg';
import SubmitArrow from 'images/submit-arrow.svg';
import Page, { PageHead } from 'components/core/page';
import { HeadTags } from 'utils/constants';
import * as styles from 'styles/pages/index.module.scss';

export function Head() {
  return (
    <PageHead>
      <title id={HeadTags.siteTitle}>David Who :: Mailing List</title>
    </PageHead>
  );
}

function IndexPage() {
  return (
    <Page>
      <section className={styles.pageCenter}>
        <StaticImage src="../images/dw-triangle-logo-fadefx.png" alt="Interdimensional Doorway" />
        <h1>
          <img src={DavidWhoTextLogo} className={styles.textLogo} alt="David Who" />
        </h1>
        <h2 className={styles.smallHeading}>Mailing List Signup</h2>
        <form
          className={styles.submitBox}
          action="https://davidwho.us9.list-manage.com/subscribe/post?u=ab415a0547494178145529470&amp;id=8405f25173&amp;f_id=00380fe1f0"
          method="post"
          name="mc-embedded-subscribe-form"
          target="_self"
        >
          <label htmlFor="email-signup" aria-label="email">
            Email
          </label>
          <input
            className={styles.textInput}
            id="email-signup"
            type="email"
            name="EMAIL"
            placeholder="Enter your email address"
            required
          />
          <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
            <input type="text" name="b_ab415a0547494178145529470_8405f25173" tabIndex={-1} value="" />
          </div>
          <button className={styles.submitArrow} type="submit">
            <img src={SubmitArrow} alt="Submit" />
          </button>
        </form>
        <p className={styles.subtext}>
          <span>Show dates.</span>
          <span>Album Releases.</span>
          <span>Exclusive Mixes.</span>
        </p>
      </section>
    </Page>
  );
}

export default IndexPage;
